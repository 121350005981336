<template>
  <div class="component-wrap top">
    <div class="title-box">
      <h2>
        {{ $t("mine.my_school_hours") }}
        <!-- 我的学时 -->
      </h2>
      <span class="desc">
        {{ $t("mytime.tips") }}
        <!-- 用于记录您完成学习获得的奖励学时 -->
      </span>
    </div>
    <div class="source">
      <div class="source-item">
        <div class="label">
          {{ $t("mytime.total_hours") }}
          <!-- 总学时 -->
        </div>
        <div class="value">{{ staticInfo.sum }}</div>
      </div>
      <div class="source-item">
        <div class="label">
          {{ $t("mytime.year_hours") }}
          <!-- 本年获得学时 -->
        </div>
        <div class="value">{{ staticInfo.year }}</div>
      </div>
      <div class="source-item">
        <div class="label">
          {{ $t("mytime.month_hours") }}
          <!-- 本月获得学时 -->
        </div>
        <div class="value">{{ staticInfo.month }}</div>
      </div>
    </div>
  </div>
  <div class="component-wrap container">
    <div class="tabs">
      <div
        class="tabs-item"
        :class="activeIndex === index && 'active'"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="tabs-line"></div>
    <div class="tab-wrap">
      <div class="search">
        <a-range-picker
          v-model:value="queryParam.date"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
        />
        <div class="search-btn">
          <a-button type="primary" ghost @click="handleSearch">
            {{ $t("XB_Search") }}
            <!-- 搜索 -->
          </a-button>
        </div>
      </div>
      <div class="list">
        <a-table
          :columns="columns"
          :data-source="state.dataSource"
          rowKey="detailId"
          :bordered="false"
          :pagination="pagination"
          :loading="state.loading"
          @change="handleTableChange"
        >
          <template #action="{ text }">
            <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
          </template>
          <template #type="{ text }">
            <span>{{ resourceType[text] }}</span>
          </template>
          <template #time="{ text }">
            <span>{{ dateFormat(text) }}</span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, ref, toRefs, toRaw } from "vue";
import { getStudyTimeList } from "@/api/mine";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
export default {
  components: {},
  setup() {
    const { t: $t } = useI18n();
    const tabList = reactive([$t("mytime.all_record")]); // 全部学时记录
    const activeIndex = ref(0);
    const changeTab = (i) => {
      if (activeIndex.value === i) return;
      activeIndex.value = i;
    };

    const staticInfo = reactive({
      sum: 0,
      year: 0,
      month: 0,
    });

    const stateData = reactive({
      columns: [
        {
          title: $t("mytime.table.name_of_resource"),
          // title: "资源名称",
          dataIndex: "resourceName",
          ellipsis: true,
          width: 200,
          slots: { customRender: "tooltip" },
        },
        {
          title: $t("mytime.table.resource_type"),
          // title: "资源类型",
          dataIndex: "resourceType",
          ellipsis: true,
          slots: { customRender: "type" },
        },
        {
          title: $t("CM_Period"),
          // title: "学时",
          dataIndex: "integral",
          ellipsis: true,
          width: 100,
        },
        {
          title: $t("mytime.table.time"),
          // title: "时间",
          dataIndex: "createTime",
          ellipsis: true,
          width: 170,
          slots: { customRender: "time" },
        },
        {
          title: $t("mytime.table.descr"),
          // title: "备注说明",
          dataIndex: "reason",
          ellipsis: true,
          width: 250,
          slots: { customRender: "tooltip" },
        },
      ],
    });
    const resourceType = {
      1: $t("Pub_Tab_CT"), // 面授
      2: $t("subject"), // 课程专题
      3: $t("credits.online_courses"), // 在线课程
      6: $t("credits.training_task"), // 培训任务
      7: $t("Pub_LP"), // 学习地图
      8: $t("CM_Live"), // 直播
      10: $t("Sys_Exam"), // 考试
    };
    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return getStudyTimeList(form).then((res) => {
        staticInfo.sum = res.data.sum;
        staticInfo.year = res.data.year;
        staticInfo.month = res.data.month;
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };
    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      date: [],
    });
    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };
    return {
      dateFormat,
      tabList,
      activeIndex,
      changeTab,
      handleSearch,
      staticInfo,
      ...toRefs(stateData),
      state,
      queryParam,
      handleTableChange,
      pagination,
      resourceType,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
}
.top {
  display: flex;
  flex-direction: column;
  height: 230px;
  box-sizing: border-box;
  background: #fff url("../../../assets/image/Intersect-bg.png") no-repeat;
  background-size: 52% 55%;
  background-position: top right;
  .title-box {
    flex: 1;
    // height: 130px;
    font-weight: bold;
    color: #333;
    h2 {
      font-size: 36px;
      font-style: oblique;
      font-weight: bold;
      line-height: 40px;
    }
    .desc {
      font-size: 16px;
    }
  }
  .source {
    flex: 1;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .source-item {
      flex: 1;
      position: relative;
      text-align: center;
      &::after {
        content: "";
        width: 2px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: #999;
      }
      &:last-child::after {
        background: transparent;
      }
      .label {
        height: 20px;
        line-height: 16px;
        font-size: 14px;
        color: #666;
      }
      .value {
        font-size: 40px;
        color: #333;
        font-weight: bold;
      }
    }
  }
}
.container {
  height: calc(100% - 175px);
  margin-top: 16px;
  padding: 0;
  .tabs {
    .mixinFlex(flex-start; center);
    padding: 30px 30px 0;
    &-item {
      font-size: 16px;
      margin: 0 10px;
      line-height: 1;
      position: relative;
      color: #999;
      padding: 4px 5px;
      cursor: pointer;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        bottom: -20px;
        left: 0;
        transition: all 0.2s;
      }
      &.active {
        color: var(--theme);
        &::after {
          background-color: var(--theme);
        }
      }
    }
  }
  .tabs-line {
    margin-top: 20px;
    border-bottom: 1px solid #eee;
  }
  .tab-wrap {
    padding: 20px 30px 30px;
    .search-btn {
      display: inline-block;
      margin-left: 12px;
    }
    .list {
      margin-top: 16px;
      ::v-deep(.ant-table-pagination.ant-pagination) {
        float: none;
        text-align: center;
      }
    }
  }
}
</style>
